.Container {
  margin: 0 auto;
  padding: 0 2.5rem;

  ul, ol {
    list-style: none;
    padding: 0;
  }

  > header {
    text-align: center;
  }
  
  @media (min-width: 640px) {
    max-width: 64.0rem;
  } 
}

.Row {
  display: flex;
  align-items: center;
  > div {
    white-space: nowrap;
    &:first-child {
      flex: 1;
    }
    &:last-child {
      flex: 1;
      text-align: right;
    }
  }
}