@import '../../variables';

.Portfolio {
  h1 {
    color: $white;
    font-size: 1.4rem;
    text-transform: uppercase;
  }
  h2 {
    font-size: 3.2rem;
    text-transform: capitalize;
    white-space: nowrap;
  }
  h3 {
    color: $white;
    font-size: 1.8rem;
  }

  > header {
    text-align: center;
    > div {
      padding: 2.0rem 0;
    }
  }
}