@import './variables';
// @import '~@blueprintjs/core/lib/scss/variables';
@import '~@blueprintjs/core/lib/css/blueprint.css';
@import '~@blueprintjs/icons/lib/css/blueprint-icons.css';
@import '~@blueprintjs/datetime/lib/css/blueprint-datetime.css';

:root {
  color-scheme: light dark;
  color: $white;
  background-color: $black;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (prefers-color-scheme: light) {
  :root {
    color: $black;
    background-color: $white;
  }
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  overflow: hidden;
}

* {

  &,
  &:before,
  &:after {
    box-sizing: inherit;
  }
}

body {
  color: $white;
  font-size: 1.4em;
  margin: 0;
}

sup {
  font-size: 50%;
  font-weight: normal;
}

.flex {
  display: flex;

  &.flex-column {
    flex-direction: column;
  }

  &.x-centered {
    justify-content: center;
  }

  >* {
    &.flex-0 {
      flex: 0;
    }

    &.flex-1 {
      flex: 1;
    }

    &.half {
      flex-basis: 50%;
    }
  }
}

// Blueprint
.bp3-menu,
.bp3-navbar {
  background-color: transparent;
}

.bp3-menu {
  border-radius: 0;
  min-width: 0;
  padding: 0;
}

.bp3-menu-item {
  border-radius: 0;
}

.bp3-button {
  border-radius: 2em;
}

.bp3-control-group {
  >* {
    border-radius: 0;
  }
}

.bp3-toast {
  border-radius: 0;
}

.bp3-dark {
  &.bp3-popover {
    .bp3-popover-arrow-fill {
      fill: $black;
    }

    .bp3-popover-content {
      background-color: $black;
    }
  }

  &.bp3-dialog {
    background-color: $black;
    min-width: 50%;
  }

  .bp3-menu,
  .bp3-navbar,
  .bp3-dialog-header,
  .bp3-datepicker {
    background-color: transparent;
    box-shadow: none;
  }

  .bp3-dialog-header {
    box-shadow: none;
  }
}

.bp3-overlay {
  &.bp3-overlay-scroll-container {
    overflow: hidden auto;
  }
}

.bp3-dialog {
  border-radius: 0;
  margin: 2.5rem;
}